import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import AppLayout from "@/layouts/AppLayout"

export default [
  {
    path: '',
    name: 'Account',
    component: () => import('../pages/user/Account'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'account.my_account', layout: AppLayout, authenticate: true }
  },
  {
    path: 'invitations',
    name: 'Invitations',
    component: () => import('../pages/user/Invitations'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'account.my_account', layout: AppLayout, authenticate: true, noNavigation: true }
  },
  {
    path: 'companies',
    name: 'MyCompanies',
    component: () => import('../pages/user/MyCompanies'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'account.my_account', layout: AppLayout, authenticate: true, noNavigation: true }
  }
]
