import CryptoJS from "crypto-js"
import api from "../../utils/api_factory"
import to from "await-to-js"
import {dataSnifer, sleep} from "@/utils/helpers.service"
import {RIGHTS, hasRight, checkRights} from "@/utils/rights.service"
import {MENU_COMMERCIAL, MENU_RH} from "@/utils/menu.service"
import {
  APP_LOADING_PERCENT,
  APP_STATUS,
  CURRENT_INVITATIONS,
  CURRENT_INVITATION_UPDATE,
  CURRENT_POSITIONS,
  CURRENT_POSITION,
  CURRENT_RIGHTS,
  LOGIN_SUCCESS,
  LOG_OUT,
  NAVIGATION_DATA,
  NEW_NAVIGATION_DATA,
  INVITATION_EMAIL,
  TEXT_REGISTER,
  CURRENT_COMPANY,
  COMPANY_TOKEN,
  CURRENT_MENUS,
  DEFAULT_TEAM,
  GLOBAL_MESSAGES,
  COMPANY_NB_PURCHASES_BASKETS,
  CURRENT_NB_NOTIFICATIONS,
  CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE
} from "../mutation.constant"

const FIELDS_SNIFER_CURRENT_POSITION = '_id address advance_balance available_balance avatar birthdate company contract_type created_companies entry_date executive favorite first_name gender isSalaried isRhAvailable last_name leavingReason name rights rtt_balance recovery_balance teams type state user userEmail workDays'
const _formatUser = function (userData) {
  return dataSnifer(userData, [
    'avatar',
    'date_to_delete',
    'state_inactive_by_user',
    'email',
    'first_name',
    'gender',
    'last_name',
    'state',
    'updatedAt',
    '_id'
  ])
}

const INITIAL_STATE_CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE = {
  searchFields: true,
  filterCategories: true
}

const INITIAL_STATE = {
  authenticated: false,
  currentUser: null,
  currentInvitations: [],
  currentPositions: [],
  currentNbNotifications: 0,
  currentNbNotificationsLastUpdate: null,
  navigationsData: [],
  currentPosition: null,
  tokenUserV3: null,
  invitationEmail: null,
  textRegister: null,
  currentRights: {},
  currentSearchOptionsDocumentLine: JSON.parse(JSON.stringify(INITIAL_STATE_CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE)),
  menuCommercial: [],
  menuRh: [],
  defaultTeam: null,
  globalMessages: [],
  nbPurchasesBasket: 0,
}

const state = {
  ...INITIAL_STATE,
  ...{
    tokenUserV3: localStorage.getItem('tokenUserV3') || null,
    navigationsData: [],
  }
}

const getters = {
  isAuthenticated: state => state.authenticated,
  currentInvitations: state => state.currentInvitations,
  currentPositions: state => state.currentPositions,
  navigationsData: state => state.navigationsData,
  currentPosition: state => state.currentPosition,
  currentNbNotifications: state => state.currentNbNotifications,
  currentUser: state => state.currentUser,
  currentUserToken: state => state.tokenUserV3,
  invitationEmail: state => state.invitationEmail,
  textRegister: state => state.textRegister,
  currentRights: state => state.currentRights,
  menuCommercial: state => state.menuCommercial,
  menuRh: state => state.menuRh,
  defaultTeam: state => state.defaultTeam,
  globalMessages: state => state.globalMessages,
  nbPurchasesBasket: state => state.nbPurchasesBasket,
  currentSearchOptionsDocumentLine: state => state.currentSearchOptionsDocumentLine
}

const actions = {
  $a_registerByPassword: function ({ commit, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      let body = JSON.parse(JSON.stringify(data.body))

      body.password = CryptoJS.SHA512(body.password).toString(CryptoJS.enc.Hex)

      let [err, resp] = await to(api.autoSigned.auth.register({ body: body }))
      if (err) return reject(err.response.data)

      // L'utilisateur venait d'une invitation par email, son email a donc déja été vérifié, l'API a retourné un token, on le log directement
      if (resp.data.newUser && resp.data.newUser.state === 'confirmed') {
        commit(LOGIN_SUCCESS, {
          token: resp.data.token,
          user: _formatUser(resp.data.newUser)
        })

        api.user.setAuthorization(resp.data.token)
      }

      return resolve(resp)
    })
  },
  $a_registerConfirm: async function ({ commit, dispatch }, data) {
    api.autoSigned.setAuthorization(data.token)

    let [err, resp] = await to(api.autoSigned.auth.confirm())
    if (err) return { err: err.response }

    if (resp.data.updatedUser && resp.data.token) {
      commit(LOGIN_SUCCESS, {
        token: resp.data.token,
        user: _formatUser(resp.data.updatedUser)
      })

      api.user.setAuthorization(resp.data.token)
    }

    api.autoSigned.removeAuthorization()

    return resp.data
  },
  $a_domainConfirm: async function ({ commit, dispatch }, data) {
    api.autoSigned.setAuthorization(data.token)

    let [err, resp] = await to(api.autoSigned.service.confirmDomain({ id: data.id }))
    if (err) return { err: err.response }

    return resp.data
  },
  $a_loginByPassword: async function ({ commit, dispatch }, { email, password, recaptchaToken }) {
    let encryptedPassword = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex)

    let [err, resp] = await to(api.autoSigned.auth.login({ body: { email, password: encryptedPassword, recaptchaToken } }))
    if (err) return { err }

    commit(LOGIN_SUCCESS, {
      token: resp.data.token,
      user: _formatUser(resp.data.user)
    })

    api.user.setAuthorization(resp.data.token)

    return { data: resp.data }
  },
  $a_loginByGoogle: async function ({ commit, dispatch }, response) {
    let data = {
      credential: response.credential,
      client_id: response.client_id
    }

    let [err, resp] = await to(api.autoSigned.auth.google({ body: data }))
    if (err) return { err }

    commit(LOGIN_SUCCESS, {
      token: resp.data.token,
      user: _formatUser(resp.data.user)
    })

    api.user.setAuthorization(resp.data.token)

    return { data: resp.data }
  },
  $a_loginByLinkedinUrl: async function ({ commit, dispatch }) {
    // TODO move in conf file
    const responseType = 'code'
    const clientId = '86i3u57h6z37vg'
    const redirectUri = encodeURI(process.env.VUE_APP_LOCAL_URL + '/login')
    const scope = 'r_emailaddress,r_liteprofile'
    return `${process.env.VUE_APP_LINKEDIN_AUTH_URL}response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`
  },
  $a_loginByLinkedin: async function ({ commit, dispatch }, code) {
    const body = {
      grant_type: 'authorization_code',
      code,
      'redirect_uri': process.env.VUE_APP_LOCAL_URL + '/login'
    }

    let [err, resp] = await to(api.autoSigned.auth.linkedin({ body }))
    if (err) return { err }

    commit(LOGIN_SUCCESS, {
      token: resp.data.token,
      user: _formatUser(resp.data.user)
    })

    api.user.setAuthorization(resp.data.token)

    return { data: resp.data }
  },
  $a_resetPassword: async function ({ commit, dispatch }, data) {
    let [err, resp] = await to(api.autoSigned.auth.passwordReset({ body: { email: data.email } }))
    if (err) return { err: err.response }

    return resp.data
  },
  $a_resetPasswordConfirm: async function ({ commit, dispatch }, data) {
    api.autoSigned.setAuthorization(data.token)

    let body = JSON.parse(JSON.stringify(data.body))

    body.password = CryptoJS.SHA512(body.password).toString(CryptoJS.enc.Hex)

    let [err, resp] = await to(api.autoSigned.auth.passwordConfirm({ body: body }))
    if (err) return { err: err.response }

    api.autoSigned.removeAuthorization()

    return resp.data
  },
  $a_logout ({ commit, dispatch }, to = null) {
    commit(LOG_OUT)
    commit(COMPANY_TOKEN, null)
    commit(CURRENT_COMPANY, null)

    //this._vm.$socket.emit('UNREGISTER', this._vm.$socket.id)
  },
  $a_setCurrentUser: function ({ commit, dispatch, state }) {
    return new Promise(async (resolve, reject) => {
      if (!state.tokenUserV3) return resolve(null)

      if (!state.authenticated && !state.currentUser) {
        // CHECK VALIDITY TOKEN
        api.user.setAuthorization(state.tokenUserV3)

        let [err, resp] = await to(api.user.getCurrent())
        commit(APP_LOADING_PERCENT, 23)
        if (err) return reject(err.response.data)

        commit(LOGIN_SUCCESS, { user: _formatUser(resp.data.user) })
      }

      return resolve(state.authenticated)
    })
  },
  $a_refreshCurrentUser: async function ({ commit }) {
    let [err, resp] = await to(api.user.getCurrent())
    if (err) throw err

    commit(LOGIN_SUCCESS, { user: _formatUser(resp.data.user) })
  },
  $a_loadUserDatas: async function ({ commit, dispatch, state, socket }) {
    if (!state.authenticated) return null
    commit(APP_STATUS, 'loading')
    commit(APP_LOADING_PERCENT, 0)

    // Socket register
    //this._vm.$socket.emit('REGISTER_USER', { socketId: this._vm.$socket.id, user: state.currentUser._id })

    await dispatch('$a_getNavigationData')

    let [err, resp] = await to(Promise.all([
      dispatch('$a_refreshCurrentPositions'),
      dispatch('$a_refreshCurrentInvitations')
    ]))
    if (err) return err

    commit(APP_LOADING_PERCENT, 43)

    let currentPositionId = localStorage.getItem('currentPosition')
    if (!currentPositionId || (currentPositionId && state.currentPositions.findIndex(p => p._id === currentPositionId) < 0)) {
      if (state.currentPositions.length > 0) {
        currentPositionId = state.currentPositions[0]._id
      }
    }
    if (!currentPositionId) { return null }

    [err, resp] = await to(api.user.getPositions({ id: state.currentUser._id, filters: { state: 'all', populate: 'company' } }))
    if (err) return err

    let positions = resp.data.positions

    let currentPosition = positions.map(p => {
      p.favorite = !!p.favorite
      return p
    }).find(p => p._id === currentPositionId)
    if (currentPosition) {
      currentPosition.company = dataSnifer(currentPosition.company, '_id logo name url')

      dispatch('$a_newPosition', dataSnifer(currentPosition, FIELDS_SNIFER_CURRENT_POSITION))
    }

    dispatch('$a_setConfigGlobalApp')

    commit(APP_LOADING_PERCENT, 56)

    return null
  },
  $a_switchPosition: async function ({ commit, dispatch, state }, data) {
    await dispatch('$a_resetCompanyStore')

    let { positionId } = data

    let position = state.currentPositions.find(p => p._id === positionId)
    if (!position) {
      return { err: new Error("Le poste demandé n'a pas été trouvé.") }
    }

    let [err, resp] = await to(api.user.getPositions({ id: state.currentUser._id, filters: { state: 'all', company: position.company._id, populate: 'company' } }))
    if (err) return { err }
    if (resp.data.positions.length === 0) return { err: new Error("Le poste demandé n'a pas été trouvé.") }

    let newCurrentPosition = resp.data.positions[0]
    newCurrentPosition.company = dataSnifer(newCurrentPosition.company, '_id logo name url')
    newCurrentPosition = dataSnifer(newCurrentPosition, FIELDS_SNIFER_CURRENT_POSITION)

    dispatch('$a_newPosition', newCurrentPosition)

    return { newPosition: position }
  },
  $a_updateCurrentInvitation: async function ({ commit, dispatch, state }, data) {
    commit(CURRENT_INVITATION_UPDATE, data.invitation)

    let [err, resp] = await to(api.user.updateInvitation({ id: data.invitation._id, userId: state.currentUser._id, body: data.invitation }))
    if (err) return { err: err.response.data }

    return resp.data
  },
  $a_refreshCurrentInvitations: async function ({ commit, dispatch }) {
    let [err, resp] = await to(api.user.getInvitations({ id: state.currentUser._id, filters: { state: 'created', populate: 'company positionFrom' } }))
    if (err) return { err: err.response.data }

    let invitations = resp.data.invitations
    let formatedInvitations = []
    if (invitations && invitations.length > 0) {
      formatedInvitations = invitations.map(invitation => {
        invitation.company = dataSnifer(invitation.company, '_id logo name url')
        invitation.positionFrom = dataSnifer(invitation.positionFrom, '_id avatar first_name last_name')

        return dataSnifer(invitation, '_id company email positionFrom state createdAt')
      })
    }
    commit(CURRENT_INVITATIONS, formatedInvitations)

    return {}
  },
  $a_refreshCurrentPositions: async function ({ commit, dispatch, state }, time) {
    if (time) await sleep(time)

    let [err, resp] = await to(api.user.getPositions({ id: state.currentUser._id, filters: { state: 'all', populate: 'company' } }))
    if (err) return { err: err.response.data }

    const formatedPositions = resp.data.positions.map(position => {
      if (position.company) {
        position.company = dataSnifer(position.company, '_id logo name url')
        position.favorite = !!position.favorite
        return dataSnifer(position, '_id avatar first_name last_name company state favorite')
      }
    })
    commit(CURRENT_POSITIONS, formatedPositions)

    if (state.currentPosition && state.currentPosition._id) {
      let currentPosition = resp.data.positions.find(p => p._id === state.currentPosition._id)
      if (currentPosition) {
        currentPosition.company = dataSnifer(currentPosition.company, '_id logo name url')

        dispatch('$a_newPosition', dataSnifer(currentPosition, FIELDS_SNIFER_CURRENT_POSITION))
      }
    }

    return {}
  },
  $a_getNavigationData: async function ({ commit, dispatch, state }) {
    const key = "navigationsData" + state.currentUser._id

    let navigationData = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : []

    commit(NAVIGATION_DATA, navigationData)
  },
  $a_setNavigation: function ({ commit, dispatch, state, rootState }, to) {
    return new Promise(async function (resolve, reject) {
      try {
        if (!rootState.authV3.authenticated) return resolve()

        let position = null
        if (to.meta.company) {
          let positionFound = state.currentPositions.find(p => p.company.url === to.params.company_url)
          if (!positionFound) return reject(new Error("Position not found"))

          position = positionFound
        }

        let navData = { positionId: null, fullPath: to.fullPath, date: new Date() }
        if (position) {
          navData.positionId = position._id
          navData.company_url = position.company.url
        }
        commit(NEW_NAVIGATION_DATA, navData)

        return resolve()
      } catch (e) {
        return reject(e)
      }
    })
  },
  $a_getInvitation: async function ({ commit, dispatch }, data) {
    api.autoSigned.setAuthorization(data.token)

    let [err, resp] = await to(api.autoSigned.auth.getInvitation({ id: data.id }))

    api.autoSigned.removeAuthorization()

    if (err) return { err: err.response }

    return resp.data
  },
  $a_confirmInvitation: async function ({ commit, dispatch }, data) {
    api.autoSigned.setAuthorization(data.token)

    let [err, resp] = await to(api.autoSigned.auth.confirmInvitation({ id: data.id, body: data.body, filters: { populate: 'company' } }))

    api.autoSigned.removeAuthorization()

    if (err) return { err: err.response }

    return resp.data
  },
  $a_setInvitationEmail: function ({ commit, dispatch }, email) {
    commit(INVITATION_EMAIL, email)
  },
  $a_setTextRegister: function ({ commit, dispatch }, text) {
    commit(TEXT_REGISTER, text)
  },
  $a_newPosition: function ({ commit, dispatch, rootState }, position) {
    // Cette information sera set dans un second temps lors du set des data d'entreprise ($a_loadCompanyInfos)
    position.isRhAvailable = false

    commit(CURRENT_POSITION, position)
    localStorage.setItem('currentPosition', position._id)

    dispatch('$a_setRights')
    dispatch('$a_setMenus')
    dispatch('$a_setCurrentSearchOptionsDocumentLine')
  },
  $a_setRights: function ({ commit, state }) {
    let newRights = {}

    Object.keys(RIGHTS).forEach(key => {
      let authorization = false

      if (state.currentPosition.state === 'active' || RIGHTS[key] === RIGHTS.SEE_MY_PAYROLLS) {
        authorization = hasRight(state.currentPosition, RIGHTS[key])
      }

      newRights[key] = authorization
    })

    commit(CURRENT_RIGHTS, newRights)
  },
  $a_setMenus: function ({ commit, state }) {
    const commercial = MENU_COMMERCIAL.filter(m => checkRights(m, state.currentPosition)).filter(m => state.currentPosition.state === 'active')
    const rh = MENU_RH
      .filter(m => checkRights(m, state.currentPosition))
      .filter(m => {
        if (m.routeName === 'ExportSells') {
          return (
            state.currentRights.SEE_MY_INVOICES ||
            state.currentRights.SEE_TEAM_INVOICES ||
            state.currentRights.SEE_ALL_INVOICES ||
            state.currentRights.SEE_ALL_PURCHASES ||
            state.currentRights.SEE_MY_PRODUCTS ||
            state.currentRights.SEE_TEAM_PRODUCTS ||
            state.currentRights.SEE_ALL_PRODUCTS ||
            state.currentRights.SEE_MY_CONTACTS ||
            state.currentRights.SEE_TEAM_CONTACTS ||
            state.currentRights.SEE_ALL_CONTACTS ||
            state.currentRights.SEE_MY_ABSENCES ||
            state.currentRights.SEE_TEAM_CONTACTS ||
            state.currentRights.SEE_ALL_CONTACTS
          )
        }

        return true
      })
      .filter(m => state.currentPosition.state === 'active' || m.routeName === 'MyPayrolls' || m.routeName === 'SafePosition')

    commit(CURRENT_MENUS, {commercial, rh})
  },
  $a_setNbNotifications: async function ({ commit, state }, params = {}) {
    // 3 minutes d'écart entre chaque appel
    const LIMIT = 3 * 60 * 1000
    const lastDate = state.currentNbNotificationsLastUpdate
    const now = new Date()
    const force = params.force || false

    if (lastDate && now - lastDate < LIMIT && !force) return

    if (state.currentPosition && state.currentPosition.state === 'active') {
      let nbNotifications = 0

      let [err, resp] = await to(api.company.notificationsList.getList({ filters: { positionSub: state.currentPosition._id } }))
      if (err) {
        commit(CURRENT_NB_NOTIFICATIONS, 0)
        return err
      }

      resp.data.notificationsLists.forEach(item => {
        item.subs.forEach(sub => {
          if (sub.positionSub === state.currentPosition._id && sub.events) {
            nbNotifications += sub.events.length
          }
        })
      })

      commit(CURRENT_NB_NOTIFICATIONS, nbNotifications)
    } else {
      commit(CURRENT_NB_NOTIFICATIONS, 0)
    }

    return null
  },
  $a_setDefaultTeam: async function ({ commit, state }) {
    let defaultTeam = null
    if (state.currentPosition.teams) {
      let defaultTeamId = state.currentPosition.teams.find(t => t.default === true)
      if (defaultTeamId) {
        let [err, resp] = await to(api.company.team.getOne({ id: defaultTeamId.team }))
        if (!err) {
          defaultTeam = resp.data.team
        }
      }
    }
    commit(DEFAULT_TEAM, defaultTeam)
  },
  $a_setConfigGlobalApp: async function ({ commit, state }) {
    let [err, resp] = await to(api.autoSigned.service.getConfigApp())
    if (!err && resp.data.config) {
      const config = resp.data.config
      if (config.config.messagesCompany) {
        commit(GLOBAL_MESSAGES, config.config.messagesCompany.filter(m => {
          let display = true

          if (m.endDate) {
            display = new Date(m.endDate) > new Date()
          }

          return (display && m.state === 'active')
        }))
      }
    }
  },
  $a_getNbPurchaseBasket: async function ({ commit, dispatch, state, rootState }) {
    if (state.currentRights.SEE_MY_BASKETS) {
      let [err, resp] = await to(api.company.basket.getList({ filters: { state: 'waiting', type: 'purchase', limit: 1 } }))
      if (!err) {
        commit(COMPANY_NB_PURCHASES_BASKETS, resp.data.total)
      }
    }
  },
  $a_setCurrentSearchOptionsDocumentLine: async function ({ commit, state }) {
    // Clé de stockage dans le localStorage
    const key = 'currentSearchOptionsDocumentLine' + state.currentPosition.company._id

    // Récupération de la valeur dans le localStorage
    let value = null
    try {
      value = JSON.parse(localStorage.getItem(key))
    } catch (e) {
      console.log(e)
    }

    // On s'assure que tous les champs par défaut soient set s'ils sont manquants dans le localStorage
    if (!value) value = {}
    value = {...JSON.parse(JSON.stringify(INITIAL_STATE_CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE)), ...value}

    commit(CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE, value)
  },
  $a_changeCurrentSearchOptionsDocumentLine: async function ({ commit, state }, value) {
    commit(CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE, value)
  }
}

const mutations = {
  [LOGIN_SUCCESS]: (state, data) => {
    state.authenticated = true
    state.currentUser = data.user

    if (data.token) {
      localStorage.setItem('tokenUserV3', data.token)
      state.tokenUserV3 = data.token
    }
  },
  [LOG_OUT]: (state) => {
    localStorage.removeItem('tokenUserV3')
    state = {...INITIAL_STATE}
  },
  [CURRENT_INVITATION_UPDATE]: (state, invitation) => {
    state.currentInvitations = state.currentInvitations.map(i => {
      if (i._id === invitation._id) {
        return invitation
      }

      return i
    })
  },
  [CURRENT_INVITATIONS]: (state, invitations) => {
    state.currentInvitations = invitations
  },
  [CURRENT_POSITIONS]: (state, positions) => {
    state.currentPositions = positions
  },
  [CURRENT_POSITION]: (state, position) => {
    state.currentPosition = position
  },
  [CURRENT_RIGHTS]: (state, rights) => {
    state.currentRights = rights
  },
  [CURRENT_MENUS]: (state, menu) => {
    if (menu.commercial) {
      state.menuCommercial = menu.commercial
    }
    if (menu.rh) {
      state.menuRh = menu.rh
    }
  },
  [CURRENT_NB_NOTIFICATIONS]: (state, nbNotifications) => {
    state.currentNbNotifications = nbNotifications
    state.currentNbNotificationsLastUpdate = new Date()
  },
  [COMPANY_NB_PURCHASES_BASKETS]: (state, value) => {
    state.nbPurchasesBasket = value
  },
  [DEFAULT_TEAM]: (state, team) => {
    state.defaultTeam = team
  },
  [NAVIGATION_DATA]: (state, data) => {
    state.navigationsData = data
  },
  [NEW_NAVIGATION_DATA]: (state, extraData) => {
    let index = state.navigationsData.findIndex(d => d.positionId === extraData.positionId)

    if (index < 0) {
      state.navigationsData.push(extraData)
    } else {
      state.navigationsData[index] = extraData
    }

    state.navigationsData = state.navigationsData.sort((a, b) => new Date(b.date) - new Date(a.date))

    localStorage.setItem('navigationsData' + state.currentUser._id, JSON.stringify(state.navigationsData))
  },
  [INVITATION_EMAIL]: (state, email) => {
    state.invitationEmail = email
  },
  [TEXT_REGISTER]: (state, email) => {
    state.textRegister = email
  },
  [GLOBAL_MESSAGES]: (state, messages) => {
    state.globalMessages = messages
  },
  [CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE]: (state, value) => {
    state.currentSearchOptionsDocumentLine = value
    // Clé de stockage dans le localStorage
    const key = 'currentSearchOptionsDocumentLine' + state.currentPosition.company._id
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
