import api from "../../utils/api_factory"
import to from 'await-to-js'
import {isBirthday, dataSnifer, isCompanyType, sleep} from "@/utils/helpers.service"
import {
  APP_LOADING_PERCENT,
  COMPANY_LOADING_PERCENT,
  COMPANY_TOKEN,
  COMPANY_STATUS,
  CURRENT_COMPANY,
  CURRENT_COMPANY_POSITIONS,
  CURRENT_COMPANY_POSITIONS_LOADING,
  LOCAL_FILTERS_PURCHASES,
  COMPANY_MISSING_FIELDS,
  CURRENT_SUBSCRIPTIONS,
  CURRENT_POSITION,
  COMPANY_CURRENT_EXPORT_PACKAGES,
} from "../mutation.constant"

const FORMAT_POSITION_FIELDS = [
  '_id',
  'address',
  'advance_balance',
  'available_balance',
  'avatar',
  'birthdate',
  'birthdayDisplay',
  'company',
  'contract_type',
  'entry_date',
  'executive',
  'description',
  'first_name',
  'gender',
  'isBirthday',
  'isHere',
  'isSalaried',
  'isRhAvailable',
  'last_name',
  'leavingReason',
  'name',
  'personal_email',
  'personal_phone_fixe',
  'phone',
  'professional_email',
  'professional_phone',
  'professional_phone_fixe',
  'release_date',
  'rights',
  'rtt_balance',
  'recovery_balance',
  'tags',
  'social_secu_number',
  'state',
  'statsSafeFiles',
  'statsSafeFilesForPosition',
  'teams',
  'user',
  'userEmail',
  'workDays',
  'workDaysComment'
].join(' ')

let purchaseFilters = {}
if (localStorage.getItem('purchaseFilters')) {
  purchaseFilters = JSON.parse(localStorage.getItem('purchaseFilters'))
} else {
  purchaseFilters = { date: 'all', state: 'all', search: '' }
}

const state = {
  companyLoadingPercent: 0,
  companyStatus: 'loading',
  companyReadOnly: false,
  currentCompany: null,
  currentCompanyPositions: [],
  currentCompanyPositionsLoading: false,
  displayFecFields: false,
  tokenCompanyV3: null,
  purchaseFilters: purchaseFilters,
  currentCompanyMissingFields: [],
  currentSubscriptions: [],
  currentExportPackages: [
    /* {
    _id: 1,
    state: "created" || "finished" || "error",
    fileName: "mon export.csv",
  } */]
}

const getters = {
  companyStatus: state => state.companyStatus,
  companyLoadingPercent: state => state.companyLoadingPercent,
  companyReadOnly: state => state.companyReadOnly,
  currentCompany: state => state.currentCompany,
  currentCompanyPositions: state => state.currentCompanyPositions,
  currentCompanyPositionsLoading: state => state.currentCompanyPositionsLoading,
  getPurchaseFilters: () => state.purchaseFilters,
  currentCompanyMissingFields: () => state.currentCompanyMissingFields,
  displayFecFields: () => state.displayFecFields,
  currentSubscriptions: () => state.currentSubscriptions,
  currentExportPackages: () => state.currentExportPackages,
  tokenCompanyV3: () => state.tokenCompanyV3
}

const actions = {
  $a_loadCompanyDatas: async function ({ commit, dispatch, rootState, state }, { toRoute, fromRoute }) {
    if (state.companyStatus !== 'loading') {
      commit(COMPANY_STATUS, 'loading')
      commit(COMPANY_LOADING_PERCENT, 0)
    }

    let [err, respToken] = await to(api.user.getCurrentPositionToken({ position: rootState.authV3.currentPosition._id }))
    if (err) return err

    //this._vm.$socket.emit('REGISTER_POSITION', { socketId: this._vm.$socket.id, position: rootState.authV3.currentPosition._id, company: rootState.authV3.currentPosition.company })

    commit(COMPANY_TOKEN, respToken.data.token)
    api.company.setAuthorization(respToken.data.token)

    commit(COMPANY_LOADING_PERCENT, 17)

    // TODO LOAD COMPANY INFOS
    let errLoad = await dispatch('$a_loadCompanyInfos')
    if (errLoad) return errLoad

    if (rootState.app.appStatus === 'loading') {
      commit(APP_LOADING_PERCENT, 72)
    }

    dispatch("$a_initCompanyPositions")
    dispatch("$a_setDefaultTeam")
    dispatch('$a_setNbNotifications', { force: true })
    dispatch('$a_getCurrentSubscriptions')

    if (toRoute && ['PurchasesList', 'PurchaseBasketsList', 'PurchaseCategoriesList', 'PurchaseTvaCountList', 'PurchaseExpenseCountList'].includes(toRoute.name)) {
      await dispatch('$a_getNbPurchaseBasket')
    } else {
      dispatch('$a_getNbPurchaseBasket')
    }

    commit(COMPANY_LOADING_PERCENT, 100)

    if (rootState.app.appStatus === 'loading') {
      commit(APP_LOADING_PERCENT, 100)
    }

    commit(COMPANY_LOADING_PERCENT, 100)
    commit(COMPANY_STATUS, 'started')
  },
  $a_loadCompanyInfos: async function ({ commit, dispatch, rootState }) {
    // LOAD CURRENT COMPANY
    let [err, resp] = await to(Promise.all([
      api.company.getCurrent(),
      api.company.getCurrentAppInfos({ filters: { populate: 'versions' } }),
    ]))
    if (err) return err

    const company = resp[0].data.company
    const companyApp = resp[1].data.companyApp

    let formatedCompany = dataSnifer(company, [
      '_id',
      'addresses',
      'absenceTypesParams',
      'cgv',
      'cgv_link',
      'commercial_court',
      'company_include_cgv',
      'company_include_cgv_physical',
      'company_type',
      'company_type_name',
      'createdAt',
      'currencies',
      'date_creation',
      'dateHolidays',
      'dateHolidaysList',
      'defaultVatRate',
      'display_reference',
      'displayCodeClient',
      'displayProductsWithVariants',
      'documentModel',
      'domain',
      'email',
      'emailExpeditor',
      'export_absences',
      'export_calendar_events',
      'export_contacts',
      'export_notifications',
      'export_products',
      'export_purchases',
      'export_sells',
      'displayFecFields',
      'fiscal_year',
      'has_aggregation',
      'has_tva_franchise',
      'languages',
      'legal_complement',
      'logo',
      'mobiles',
      'naf_ape',
      'name',
      'nic',
      'num_rcs',
      'num_trade_directory',
      'payment_delay_default',
      'validity_delay_default',
      'phones',
      'sell_electronic',
      'sell_marchandise',
      'share_capital',
      'siren',
      'state',
      'subscription_address',
      'tva_number',
      'teams',
      'url',
      'visionAnalytic',
      'visionAnalyticDefaultValue',
      'workDays',
    ])

    let formatedCompanyApp = dataSnifer(companyApp, 'containerSize containerPrivateSize nbMailsSent monthDocumentsLimit monthMailsLimit nbCreatedInvoices nbCreatedQuotes nbCreatedReceipts options versions domainVerify rhPositionsAvailable statsSafeFiles statsSafeFilesForPosition subscribedSince nbInvoicesSinceCreated nbInvoicesSinceOneYear')

    formatedCompany = { ...formatedCompany, ...formatedCompanyApp }

    let currentPack = null
    if (formatedCompany.versions) {
      formatedCompany.versions.forEach(version => {
        if (version.type === 'pack') {
          if (!currentPack) {
            currentPack = version
          } else {
            if (version.priceMonth > currentPack.priceMonth) currentPack = version
          }
        }
      })
    }
    formatedCompany.currentPack = currentPack

    // Set isRhAvailable sur la position courrante de l'utilisateur, initialement laissée à false
    if (formatedCompany.rhPositionsAvailable) {
      let currentPosition = JSON.parse(JSON.stringify(rootState.authV3.currentPosition))
      currentPosition.isRhAvailable = formatedCompany.rhPositionsAvailable.includes(currentPosition._id)
      commit(CURRENT_POSITION, currentPosition)
    }

    // Set la company en dernier pour que le poste soit complètement chargé avant
    commit(CURRENT_COMPANY, formatedCompany)

    dispatch("$a_initCompanyMissingFields")

    return null
  },
  $a_getCurrentSubscriptions: async function ({ commit }) {
    commit(CURRENT_SUBSCRIPTIONS, null)

    // TODO
  },
  $a_resetCompanyStore: async function ({ dispatch }) {
    await dispatch('$a_resetContactStore')
  },
  $a_stopLoadingCompany: function ({ commit, dispatch }) {
    commit(COMPANY_STATUS, 'started')
  },
  $a_refreshPosition: async function ({ commit, dispatch }, { positionId, timeout = 0 }) {
    if (timeout) {
      await sleep(timeout)
    }

    let [err, resp] = await to(api.company.position.getOne({ id: positionId }))
    if (!err) {
      dispatch('$a_updateCompanyPosition', { position: resp.data.position })
    }
  },
  $a_updateCompanyPosition ({ commit, dispatch }, data) {
    if (!data.position) return

    let positions = state.currentCompanyPositions.map(p => {
      if (p._id === data.position._id) {
        return dataSnifer({...p, ...data.position}, FORMAT_POSITION_FIELDS)
      } else {
        return p
      }
    })

    commit(CURRENT_COMPANY_POSITIONS, positions)
  },
  $a_initCompanyPositions: async function ({ commit, dispatch, state }) {
    // Reset current company positions
    commit(CURRENT_COMPANY_POSITIONS, [])
    commit(CURRENT_COMPANY_POSITIONS_LOADING, true)

    let [err, resp] = await to(api.company.position.getList({ filters: { state: ['active', 'inactive'], populate: 'tags' } }))
    if (err) {
      return
    }

    let positions = resp.data.positions.map(p => {
      p.isBirthday = false
      p.isRhAvailable = false

      return p
    })
    commit(CURRENT_COMPANY_POSITIONS, JSON.parse(JSON.stringify(positions)))
    commit(CURRENT_COMPANY_POSITIONS_LOADING, false)
    let formatedPositions = []

    const currentCompanyId = state.currentCompany._id // Vérification si l'entreprise courrante est tjs la bonne
    const today = new Date()
    let i = 0
    while (i < positions.length && currentCompanyId === state.currentCompany._id) {
      let position = positions[i]

      position.isBirthday = isBirthday(position.birthdate) && !(position.birthdayDisplay === false)
      position.isHere = false
      position.isRhAvailable = false

      // On évite de faire une requete pour les utilisateurs inactifs de l'entreprise
      if (position.state === 'active') {
        position.isHere = position.workDays ? position.workDays.includes(today.getDay()) : true

        if (position.isHere) {
          let [err, resp] = await to(api.company.absence.getList({ filters: { positionTo: position._id, state: 'accepted', today: true } }))
          if (!err && resp && resp.data && resp.data.absences && resp.data.absences.length > 0) {
            position.isHere = false
          }
        }
      }

      if (state.currentCompany && state.currentCompany.rhPositionsAvailable) {
        position.isRhAvailable = state.currentCompany.rhPositionsAvailable.includes(position._id)
      }

      // Vérification si l'entreprise courrante est tjs la bonne
      if (state.currentCompany._id === position.company) {
        formatedPositions.push(dataSnifer(position, FORMAT_POSITION_FIELDS))
      }

      i++
    }

    commit(CURRENT_COMPANY_POSITIONS, JSON.parse(JSON.stringify(formatedPositions)))
  },
  $a_initCompanyMissingFields: async function ({ commit, dispatch, state, rootState }) {
    try {
      let [err, resp] = await to(api.autoSigned.service.getCompanyTypeList())
      if (err) return null

      let companyTypes = resp.data.companyTypes

      let missingFields = []

      if (!state.currentCompany.company_type) missingFields.push({ route: 'ParamsCompanyUpdate', name: 'Statut' })

      if (!isCompanyType(companyTypes, state.currentCompany, ['Autre'])) {
        if (!state.currentCompany.siren || state.currentCompany.siren === '') missingFields.push({ name: 'SIREN', route: 'ParamsCompanyFiscal' })
        if (!state.currentCompany.nic || state.currentCompany.nic === '') missingFields.push({ name: 'NIC', route: 'ParamsCompanyFiscal' })
        if (!state.currentCompany.naf_ape || state.currentCompany.naf_ape === '') missingFields.push({ name: 'NAF/APE', route: 'ParamsCompanyFiscal' })

        if (isCompanyType(companyTypes, state.currentCompany, ['EURL', 'SARL', 'SA', 'SNC', 'SAS'])) {
          if (!state.currentCompany.share_capital || state.currentCompany.share_capital === '') missingFields.push({ name: 'Capital', route: 'ParamsCompanyFiscal' })
        }

        if (isCompanyType(companyTypes, state.currentCompany, ['EURL', 'SARL', 'SA', 'SNC', 'SAS', 'SCI'])) {
          if (!state.currentCompany.commercial_court || state.currentCompany.commercial_court === '') missingFields.push({ name: 'Greffe', route: 'ParamsCompanyFiscal' })
        }
      }

      if (!state.currentCompany.addresses || (state.currentCompany.addresses && state.currentCompany.addresses.length === 0)) {
        missingFields = [...missingFields, ...[
          { route: 'ParamsCompanyContact', name: 'Adresse' },
          { route: 'ParamsCompanyContact', name: 'Code Postal' },
          { route: 'ParamsCompanyContact', name: 'Ville' },
          { route: 'ParamsCompanyContact', name: 'Pays' }
        ]]
      } else {
        let address = state.currentCompany.addresses[0]
        if (!address.street) missingFields.push({ route: 'ParamsCompanyContact', name: 'Adresse' })
        if (!address.zip_code) missingFields.push({ route: 'ParamsCompanyContact', name: 'Code Postal' })
        if (!address.city) missingFields.push({ route: 'ParamsCompanyContact', name: 'Ville' })
        if (!address.country) missingFields.push({ route: 'ParamsCompanyContact', name: 'Pays' })
      }

      commit(COMPANY_MISSING_FIELDS, missingFields)

      return missingFields
    } catch (e) {
      return []
    }
  },
  $a_addExportPackage: async function ({ commit, state }, pack) {
    let packages = JSON.parse(JSON.stringify(state.currentExportPackages))
    packages.push(pack)

    commit(COMPANY_CURRENT_EXPORT_PACKAGES, packages)

    function replacePackInPackages (packages, pack) {
      return JSON.parse(JSON.stringify(packages)).map(p => {
        if (p._id === pack._id) return pack
        return p
      })
    }

    let packageWaiting = true
    let newPack = JSON.parse(JSON.stringify(pack))

    while (packageWaiting) {
      newPack = await getPackage(pack)
      if (!newPack) {
        packageWaiting = false
        newPack = JSON.parse(JSON.stringify(pack))
        newPack.state = 'error'
      } else {
        packageWaiting = newPack.state === 'created'
        if (packageWaiting) {
          packages = replacePackInPackages(state.currentExportPackages, newPack)
          commit(COMPANY_CURRENT_EXPORT_PACKAGES, packages)

          await sleep(3000)
        }
      }
    }

    packages = replacePackInPackages(state.currentExportPackages, newPack)
    commit(COMPANY_CURRENT_EXPORT_PACKAGES, packages)
  },
  $a_removeExportPackage: async function ({ commit, state }, packId) {
    let packages = JSON.parse(JSON.stringify(state.currentExportPackages))
    packages = packages.filter(p => p._id !== packId)
    commit(COMPANY_CURRENT_EXPORT_PACKAGES, packages)
  }
}
const getPackage = async function (pack) {
  let [err, resp] = await to(api.company.package.getOne({ id: pack._id }))
  if (err) return null

  return resp.data.package
}

const mutations = {
  [CURRENT_COMPANY]: (state, company) => {
    state.currentCompany = company
    if (company && company.options) {
      state.displayFecFields = (company.displayFecFields === true && company.options.fec === true)
    } else {
      state.displayFecFields = false
    }

    if (company && company.versions) {
      state.companyReadOnly = company.versions.findIndex(v => v.name.toLowerCase() === 'api') >= 0
    }
  },
  [CURRENT_COMPANY_POSITIONS]: (state, positions) => {
    state.currentCompanyPositions = positions
  },
  [CURRENT_SUBSCRIPTIONS]: (state, subscription) => {
    state.currentSubscriptions = subscription
  },
  [CURRENT_COMPANY_POSITIONS_LOADING]: (state, loading) => {
    state.currentCompanyPositionsLoading = loading
  },
  [COMPANY_TOKEN]: (state, token) => {
    state.tokenCompanyV3 = token
  },
  [COMPANY_STATUS]: (state, status) => {
    state.companyStatus = status
  },
  [COMPANY_LOADING_PERCENT]: (state, percent) => {
    state.companyLoadingPercent = percent
  },
  [COMPANY_MISSING_FIELDS]: (state, fields) => {
    state.currentCompanyMissingFields = fields
  },
  [LOCAL_FILTERS_PURCHASES]: (state, filters) => {
    let newFilter = {}
    if (filters.state) newFilter.state = filters.state
    if (filters.date) newFilter.date = filters.date
    if (filters.minDate) newFilter.minDate = filters.minDate
    if (filters.maxDate) newFilter.maxDate = filters.maxDate
    if (filters.search) newFilter.search = filters.search
    localStorage.setItem('purchaseFilters', JSON.stringify(newFilter))
    state.purchaseFilters = newFilter
  },
  [COMPANY_CURRENT_EXPORT_PACKAGES]: (state, packages) => {
    state.currentExportPackages = packages
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
