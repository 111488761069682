import AppLayout from '../layouts/AppLayout'
import store from "../store"
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"

const beforeEnterCheckerManageApi = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_API) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerManageFiscal = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_FISCAL) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerManageCompany = function (to, from, next, redirectToPosition = false) {
  let position = store.getters.currentPosition

  let redirect = { name: "Error403" }
  if (redirectToPosition && store.getters.currentCompany && position) {
    redirect = { name: 'ParamsPosition', params: { company_url: store.getters.currentCompany.url, id: position._id } }
  }

  if (position) {
    if (!store.getters.currentRights.MANAGE_COMPANY) return next(redirect)
  }
}

const beforeEnterCheckerManageRights = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_RIGHTS) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerUpdateUser = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.UPDATE_USER) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerManageTeams = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_TEAMS) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerInviteUser = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.INVITE_USER) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerUsers = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.SEE_ALL_USERS && !store.getters.currentRights.SEE_MY_TEAMS) return next({ name: "Error403" })
  }
}

const beforeEnterCheckerCollaborators = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.SEE_COLLABORATORS) return next({ name: "Error403" })
  }
}

export default [
  {
    path: '/:company_url/params',
    component: () => import('../layouts/subLayout/CompanyParamsLayout'),
    children: [
      {
        path: '/:company_url/params',
        name: 'ParamsCompany',
        redirect: { name: 'ParamsCompanyUpdate' }
      },
      /** COMPANY */
      {
        path: '/:company_url/params/company/update',
        name: 'ParamsCompanyUpdate',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next, true)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.generals_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/company/products',
        name: 'ParamsCompanyProducts',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.products_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/company/contact',
        name: 'ParamsCompanyContact',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.contact_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/company/fiscal',
        name: 'ParamsCompanyFiscal',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.accounting_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/company/absence',
        name: 'ParamsCompanyAbsence',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.absence_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/company/CGV',
        name: 'ParamsCompanyCGV',
        component: () => import('../pages/company/params/Company.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.CGV', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** MODELS DE DOCUMENTS */
      {
        path: '/:company_url/params/document-models',
        name: 'ParamsManageModels',
        component: () => import('../pages/company/params/documentModel/DocumentModels.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/document-model/update/:id',
        name: 'ParamsUpdateDocumentModel',
        component: () => import('../pages/company/params/documentModel/DocumentModelForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/document-model/create',
        name: 'ParamsCreateDocumentModel',
        component: () => import('../pages/company/params/documentModel/DocumentModelForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** DOCUMENTS NUMEROTATION */
      {
        path: '/:company_url/params/numbers',
        name: 'ParamsNumbers',
        component: () => import('../pages/company/params/document/Numbers.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.numbers.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** DOCUMENTS TRAD */
      {
        path: '/:company_url/params/i18n',
        name: 'Paramsi18n',
        component: () => import('../pages/company/params/document/i18n.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.i18n.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/vision',
        name: 'ParamsVision',
        component: () => import('../pages/company/params/document/Vision.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.company.generals_infos', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** POSITIONS */
      {
        path: '/:company_url/params/positions',
        redirect: { name: 'ParamsPositions' }
      },
      {
        path: '/:company_url/params/positions',
        name: 'ParamsPositions',
        component: () => import('../pages/company/params/position/Positions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerUsers(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_list', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/positions',
        name: 'ParamsPositionsCollaborator',
        component: () => import('../pages/company/params/position/Positions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerCollaborators(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_team', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/positions',
        name: 'ParamsPositionsExtern',
        component: () => import('../pages/company/params/position/Positions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerUsers(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_team', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/positions',
        name: 'ParamsPositionsInactives',
        component: () => import('../pages/company/params/position/Positions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerUsers(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_inactives', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/position/:id',
        name: 'ParamsPosition',
        component: () => import('../pages/company/params/position/Position.vue'),
        beforeEnter: async (to, from, next) => {
          // TODO mettre la verif au niveau du router et non pas dans la page

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/position/:id/update',
        name: 'ParamsPositionUpdate',
        component: () => import('../pages/company/params/position/PositionUpdate.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerUpdateUser(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.users.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/rights-types',
        name: 'ParamsRightsTypes',
        component: () => import('../pages/company/params/rightType/RightsTypes.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageRights(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.right_types.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/rights-types/create',
        name: 'ParamsCreateType',
        component: () => import('../pages/company/params/rightType/RightTypeForm'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageRights(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.right_types.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/rights-types/update/:id',
        name: 'ParamsUpdateType',
        component: () => import('../pages/company/params/rightType/RightTypeForm'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageRights(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.right_types.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/invite-user',
        name: 'ParamsInviteUser',
        component: () => import('../pages/company/params/position/InviteUser.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerInviteUser(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.invite.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/teams',
        name: 'ParamsTeams',
        component: () => import('../pages/company/params/team/Teams.vue'),
        beforeEnter: async (to, from, next) => {
          let position = store.getters.currentPosition
          if (position) {
            let access = store.getters.currentRights.MANAGE_TEAMS || store.getters.currentRights.SEE_MY_TEAMS

            if (!access) return next({ name: "Error403" })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.teams.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/team/create',
        name: 'ParamsTeamCreate',
        component: () => import('../pages/company/params/team/TeamCreate.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageTeams(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.teams.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/team/:id/update',
        name: 'ParamsTeamUpdate',
        component: () => import('../pages/company/params/team/TeamUpdate.vue'),
        beforeEnter: async (to, from, next) => {
          let position = store.getters.currentPosition
          if (position) {
            let access = store.getters.currentRights.MANAGE_TEAMS || store.getters.currentRights.SEE_MY_TEAMS

            if (!access) return next({ name: "Error403" })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.teams.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** PAYMENT METHODS */
      {
        path: '/:company_url/params/payment-methods',
        name: 'ParamsPaymentMethods',
        component: () => import('../pages/company/params/paymentModes/PaymentMethods.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.payment_modes.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/payment-methods/create',
        name: 'ParamsPaymentMethodCreate',
        component: () => import('../pages/company/params/paymentModes/PaymentMethodForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.payment_modes.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/payment-methods/update/:id',
        name: 'ParamsPaymentMethodUpdate',
        component: () => import('../pages/company/params/paymentModes/PaymentMethodForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.payment_modes.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** EMAILS */
      {
        path: '/:company_url/params/emails',
        name: 'ParamsEmails',
        component: () => import('../pages/company/params/Emails.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.emails.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/domain',
        name: 'ParamsDomain',
        component: () => import('../pages/company/params/Domain.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.emails.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** DUNNING RULES */
      {
        path: '/:company_url/params/dunning-rules',
        name: 'ParamsDunningRules',
        component: () => import('../pages/company/params/dunningRule/DunningRules.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.numbers.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/dunning-rule/invoice/create',
        name: 'ParamsCreateDunningRuleInvoice',
        component: () => import('../pages/company/params/dunningRule/DunningRuleInvoiceForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/dunning-rule/invoice/update/:id',
        name: 'ParamsUpdateDunningRuleInvoice',
        component: () => import('../pages/company/params/dunningRule/DunningRuleInvoiceForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/dunning-rule/quote/create',
        name: 'ParamsCreateDunningRuleQuote',
        component: () => import('../pages/company/params/dunningRule/DunningRuleQuoteForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/dunning-rule/quote/update/:id',
        name: 'ParamsUpdateDunningRuleQuote',
        component: () => import('../pages/company/params/dunningRule/DunningRuleQuoteForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageCompany(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.models.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** FISCALS */
      {
        path: '/:company_url/params/closure',
        name: 'ParamsClosure',
        component: () => import('../pages/company/params/compliance/Closure'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageFiscal(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.closure.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/archiving',
        name: 'ParamsArchiving',
        component: () => import('../pages/company/params/compliance/Archiving'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageFiscal(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.archive.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/traceability',
        name: 'ParamsTraceability',
        component: () => import('../pages/company/params/compliance/Traceability.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageFiscal(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.traceability.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/documentation',
        name: 'ParamsDocumentation',
        component: () => import('../pages/company/params/compliance/Documentation.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageFiscal(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.documentation.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/certificate',
        name: 'ParamsCertificate',
        component: () => import('../pages/company/params/compliance/Certificate.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageFiscal(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.certificate.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      /** API */
      {
        path: '/:company_url/params/applications',
        name: 'ParamsApplications',
        component: () => import('../pages/company/params/developer/applications/Applications.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.api.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/applications/create',
        name: 'ParamsApplicationCreate',
        component: () => import('../pages/company/params/developer/applications/ApplicationForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'api.add', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/applications/update/:id',
        name: 'ParamsApplicationUpdate',
        component: () => import('../pages/company/params/developer/applications/ApplicationForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'api.update', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/emonsite',
        name: 'ParamsEmonsite',
        component: () => import('../pages/company/params/developer/Emonsite.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.emonsite.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/zapier',
        name: 'ParamsZapier',
        component: () => import('../pages/company/params/developer/Zapier.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.zapier.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/luminjo',
        name: 'ParamsLuminjo',
        component: () => import('../pages/company/params/developer/Luminjo.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.luminjo.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/web2contact',
        name: 'ParamsWeb2contact',
        component: () => import('../pages/company/params/developer/Web2Contact.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.web2contact.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/webhooks',
        name: 'ParamsWebhooks',
        component: () => import('../pages/company/params/developer/webhook/Webhooks'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.api.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/webhook/create',
        name: 'ParamsWebhookCreate',
        component: () => import('../pages/company/params/developer/webhook/WebhookForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.api.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
      {
        path: '/:company_url/params/webhook/update/:id',
        name: 'ParamsWebhookUpdate',
        component: () => import('../pages/company/params/developer/webhook/WebhookForm.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerManageApi(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'params.api.title_nav', layout: AppLayout, activeMenu: "params", authenticate: true, company: true }
      },
    ]
  }
]
