export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/quotes', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/quote', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/quote/' + data.id, { params: data.filters || {} })
  },
  getStats (data = {}) {
    return axios.get('/quotes/stats', { params: data.filters || {} })
  },
  createPackage (data = {}) {
    return axios.post('/quotes/package', data.body, { params: data.filters || {} })
  },
  getHtml (data = {}) {
    checkHeaders(axios, 'GET', '/quote/' + data.id + '/html')

    return axios.get('/quote/' + data.id + '/html', { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/quote/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/quote/' + data.id, { params: data.filters || {} })
  },
  postAdvance (data = {}) {
    return axios.post('/quote/' + data.id + '/advance', data.body, { params: data.filters || {} })
  },
  postSold (data = {}) {
    return axios.post('/quote/' + data.id + '/sold', data.body, { params: data.filters || {} })
  },
  postInvoice (data = {}) {
    return axios.post('/quote/' + data.id + '/invoice', data.body || {}, { params: data.filters || {} })
  },
  postProForma (data = {}) {
    return axios.post('/quote/' + data.id + '/proforma', data.body || {}, { params: data.filters || {} })
  },
  postBdc (data = {}) {
    return axios.post('/quote/' + data.id + '/bdc', data.body || {}, { params: data.filters || {} })
  },
  getLogs (data = {}) {
    if (data.id) return axios.get('/quote/' + data.id + '/logs', { params: data.filters || {} })

    return axios.get('/quotes/logs', { params: data.filters || {} })
  },
  postDeliveryReceipt (data = {}) {
    return axios.post('/quote/' + data.id + '/delivery-receipt', data.body, { params: data.filters || {} })
  },
})
