<template>
  <div id="AppLayout">
    <div id="MenuLogo">
      <b-button :disabled="!currentPosition" @click="onGoHome">
        <img alt="Wuro" :src="PUBLIC_PATH + 'img/wuro.svg'">
      </b-button>
    </div>
    <transition name="slide" mode="out-in">
      <router-view :key="navSideBarKey" name="navSideBar"/>
    </transition>

    <b-navbar sticky id="navbar" class="navbar-header shadow">
      <b-button variant="transparent" class="d-none d-sm-block d-md-none p-0 mr-3" :disabled="!currentPosition" @click="onGoHome">
        <img alt="" :src="PUBLIC_PATH + 'img/wuro.svg'" height="30">
      </b-button>

      <b-button variant="light" v-b-tooltip.hover.bottomright="appConfig.lastFeaturesLabel" @click="onLinkLastFeature">
        <b-icon icon="lightning"></b-icon>
      </b-button>

      <b-alert v-if="currentUser.state === 'inactive'"
               show
               variant="danger"
               class="mb-0 ml-3 pt-2 pb-2"
      >
        Votre compte est inactif, sa suppression est prévue le <b>{{ currentUser.date_to_delete|date }}</b>. <span v-if="currentUser.state_inactive_by_user === currentUser._id">Vous pouvez annuler cette démarche à tout moment dans les <router-link :to="{ name: 'Account', query: { tab: 2 } }">paramètres de votre compte</router-link>.</span>
      </b-alert>

      <b-navbar-nav class="ml-auto align-items-center">
        <b-dropdown size="lg" id="dropDownAvatar" variant="link" right toggle-class="text-decoration-none p-0 rounded mr-2" no-caret>
          <template #button-content>
            <b-avatar button
                      variant="light"
                      :src="currentUser.avatar"
                      :text="initials(currentUser)"
                      size="40px"
                      :badge="currentInvitations.length > 0 ? currentInvitations.length.toString() : false"
                      badge-variant="warning"
                      badge-offset="-3px"
            ></b-avatar>
          </template>

          <b-dropdown-item href="#" @click="() => $router.push({ name: 'Account' })"><b-icon icon="person"></b-icon> Mon compte</b-dropdown-item>
          <b-dropdown-item href="#" @click="() => $router.push({ name: 'Invitations' })"><b-icon icon="envelope"></b-icon> Mes invitations <b-badge :variant="currentInvitations.length > 0 ? 'warning' : 'light'" pill>{{currentInvitations.length}}</b-badge></b-dropdown-item>
          <b-dropdown-item href="#" @click="() => $router.push({ name: 'MyCompanies' })"><b-icon icon="building"></b-icon> Mes espaces entreprises</b-dropdown-item>
          <b-dropdown-item href="#" @click="logout"><span class="text-danger"><b-icon icon="power"></b-icon> Se déconnecter</span></b-dropdown-item>
        </b-dropdown>

        <b-button class="d-inline-flex align-items-center" id="CurrentPosition" v-if="currentPositions && currentPositions.length > 0"
                  v-b-toggle.sidebar-positions
                  variant="light">
          <b-avatar :src="currentPosition.company.logo" :text="initials(currentPosition.company)" variant="light" rounded size="30"></b-avatar>

          <span class="d-none d-md-inline text-dark ml-2 mr-3">{{ currentPosition.company.name|capitalize }}</span>

          <b-icon icon="list"></b-icon>
        </b-button>
        <b-button v-else variant="outline-primary" @click="() => $router.push({ name: 'AddCompany' })"><b-icon icon="plus"></b-icon> Créer un espace entreprise</b-button>
      </b-navbar-nav>
    </b-navbar>

    <b-sidebar
      id="sidebar-positions"
      title="Mes espaces entreprises"
      backdrop
      right
      shadow
      bg-variant="white"
      width="350px"
      ref="sidebarPositions"
    >
      <template #title>
        <router-link :to="{ name: 'MyCompanies' }">Mes espaces entreprises</router-link>
      </template>

      <div v-if="currentPositions && currentPositions.length > 0" class="main-position">
        <div @click="() => $router.push({ name: 'Home', params: { company_url: currentPosition.company.url } })" class="pointer">
          <b-avatar :src="currentPosition.company.logo" :text="initials(currentPosition.company)" rounded variant="light" size="45"></b-avatar>
        </div>

        <div class="flex">
          <div class="text-dark">{{ currentPosition.company.name|capitalize }}</div>

          <router-link :to="{ name: 'ParamsCompany', params: { company_url: currentPosition.company.url } }"
                       style="font-size: 14px;">
            <b-icon class="mr-1" icon="gear"></b-icon>
            Paramètres
          </router-link>
        </div>
      </div>
      <ul class="other-companies">
        <li v-for="position in currentPositions"
            v-if="position._id !== currentPosition._id && !!position.favorite"
            :key="'position_list_' + position._id"
            class="company"
            @click="() => switchCompany(position)">
          <b-avatar :src="position.company.logo" :text="initials(position.company)" rounded size="37" variant="light"></b-avatar>

          <span class="text-dark">{{ position.company.name|capitalize }}</span>
        </li>
      </ul>

      <div class="pl-2 fixed-bottom mb-3">
        <b-button variant="primary" block @click="() => $router.push({ name: 'AddCompany' })">
          Créer un espace entreprise
        </b-button>
      </div>
    </b-sidebar>

    <slot/>
  </div>
</template>

<script>
// Libs && Helpers
import {mapActions, mapGetters} from "vuex"

export default {
  name: 'AppLayout',
  computed: {
    ...mapGetters(['currentUser', 'currentPositions', 'currentPosition', 'navigationsData', 'appConfig', 'currentInvitations']),
    navSideBarKey () {
      if (this.currentPosition) return 'navSideBar-' + this.currentPosition._id
      return 'navSideBar-' + this.currentUser._id
    },
  },
  data () {
    return {
      PUBLIC_PATH: process.env.BASE_URL
    }
  },
  created () {
    var prevScrollpos = window.pageYOffset
    window.onscroll = function () {
      let navbar = document.getElementById("navbar")
      if (navbar) {
        var currentScrollPos = window.pageYOffset

        if (prevScrollpos > currentScrollPos) {
          navbar.style.top = "0"
        } else {
          navbar.style.top = "-50px"
        }
        prevScrollpos = currentScrollPos
      }
    }
  },
  methods: {
    ...mapActions(['$a_logout']),
    logout () {
      this.$a_logout()

      this.$router.push({ name: 'Login' })
    },
    onGoHome () {
      if (this.currentPosition) {
        this.$router.push({ name: 'Home', params: { company_url: this.currentPosition.company.url } })
      }
    },
    onLinkLastFeature () {
      let a = document.createElement('a')
      a.href = this.appConfig.lastFeaturesLink
      a.target = '_blank'
      a.click()
    },
    switchCompany: async function (selectedPosition, redirect = true) {
      this.loading = true
      this.overlay = false

      document.title = this.$t('home.title') + ' • ' + selectedPosition.company.name

      let navData = this.navigationsData.find(nD => nD.positionId === selectedPosition._id)

      if (navData && !['', '/', null, undefined].includes(navData.fullPath)) {
        this.$router.push(navData.fullPath)
      } else {
        this.$router.push({ name: 'Home', params: { company_url: selectedPosition.company.url } })
      }

      this.$refs.sidebarPositions.hide()
    },
  }
}
</script>

<style scoped>
#MenuLogo {
  width: 80px;
  z-index: 10000;
  padding: 5px;
  border-radius: 0;
  position:fixed;
  top:0;
  left:0;
}
#MenuLogo > button {
  width:70px;
  height: 60px;
  background-color: transparent;
  border: none;
  border-radius: 25px;
  box-shadow: none;
}
#MenuLogo > button:hover {
  background-color: #363636;
}

#dropDownAvatar {
  z-index: 10000;
}

#CurrentPosition {
  background: #fff;
  box-shadow: none !important;
  border-radius: 0;
  border: none;
  margin-bottom: -0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
}

.navbar-header {
  background: #fff;
  margin: auto;
  border-radius: 0;
  top: 0;
  z-index: 200;
  padding: .5rem 30px;
}

#AppLayout {
  height: 100%;
}

.main-position {
  display: flex;
  align-items: center;
  line-height: 17px;
  margin: 10px 15px;
  color: #4D4D4D;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-position > div {
  display: inline-block;
  padding: 10px;
}

.main-position img {
  min-width: 50px;
  max-height: 60px;
  max-width: 60px;
  background-color: #EEE;
  padding: 10px;
  border-radius: 5px;
}

.main-position .wuro-link {
  margin-top: 7px;
  font-size: 14px;
  float: none;
}

ul.other-companies {
  margin: 0;
  height: auto;
  max-height: calc(100vh - 274px);
  overflow-y: scroll;
  display: inline-block;
  width: 100%;
}

ul.other-companies li {
  background-color: #fbfbfb;
  color: #4D4D4D;
  border-bottom: 1px solid #dfdfdf;
  font-size: 15px;
  padding: 9px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

ul.other-companies li:hover {
  text-decoration: none;
  background-color: #dfdfdf;
}

ul.other-companies li:first-child {
  border-top: 1px solid #dfdfdf;
}

ul.other-companies li > span {
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  #MenuLogo {
    display:none;
  }

  #AppLayout {
    background: none;
  }

  .navbar-header {
    padding-left: 0.5rem !important;
  }
}
</style>
