export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/packages', { params: data.filters || {} })
  },
  download (data = {}) {
    return axios.get('/package/' + data.id + '/download', { params: data.filters || {}, responseType: 'arraybuffer' })
  },
  getOne (data = {}) {
    return axios.get('/package/' + data.id, { params: data.filters || {} })
  }
})
