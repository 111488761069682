<template>
  <div>
    <b-toast v-for="pack in currentExportPackages"
             :key="KEY_ID_EXPORT + pack._id"
             :id="KEY_ID_EXPORT + pack._id"
             variant="light"
             visible
             no-auto-hide
             @hide="onHide(pack)"
    >
      <template #toast-title>
        <span class="bold mr-3">Export</span>

        <span v-if="pack.extraData && pack.extraData.items && pack.extraData.items !== pack.extraData.itemsWritten">
          {{ pack.extraData.itemsWritten || 0 }} / {{ pack.extraData.items }}
        </span>
      </template>

      <div>
        <b-button v-if="pack.state === 'created' || pack.state === 'finished'"
                  size="sm"
                  block
                  variant="primary"
                  :disabled="pack.state === 'created'"
                  class="d-flex align-items-center justify-content-center"
                  v-b-tooltip.hover="'Télécharger'"
                  @click="onDownload(pack)">
          <b-spinner v-if="pack.state === 'created'" small class="mr-1"></b-spinner>
          <b-icon v-if="pack.state === 'finished'" icon="download" class="mr-1"></b-icon>

          <span class="bold">{{ pack.fileName}}</span>
        </b-button>

        <b-button v-if="pack.state === 'error'"
                  size="sm"
                  block
                  variant="outline-danger"
                  :disabled="true">
          <span v-b-tooltip.hover="'Une erreur est survenue lors de l\'export'" class="d-flex align-items-center justify-content-center">
            <b-icon icon="x" class="mr-1"></b-icon>
            <span class="bold">{{ pack.fileName}}</span>
          </span>
        </b-button>
      </div>
    </b-toast>
  </div>
</template>

<script>
// Libs && Helpers
import {mapActions, mapGetters} from 'vuex'
import to from "await-to-js"
import api from "@/utils/api_factory"

const KEY_ID_EXPORT = 'toast-export-'

export default {
  name: "exportToast",
  data  () {
    return {
      KEY_ID_EXPORT
    }
  },
  computed: {
    ...mapGetters(['currentExportPackages'])
  },
  methods: {
    ...mapActions(['$a_removeExportPackage']),
    onDownload: async function (pack) {
      let [err, resp] = await to(api.company.package.download({ id: pack._id }))
      if (err) {
        return this.toastError(err.response.data)
      }

      const fileURL = window.URL.createObjectURL(new Blob([resp.data], {type: 'application/zip'}))
      let link = document.createElement('a')

      link.href = fileURL
      link.download = pack.fileName

      link.click()

      this.$bvToast.hide(KEY_ID_EXPORT + pack._id)
    },
    onHide (pack) {
      this.$a_removeExportPackage(pack._id)
    }
  }
}
</script>
