import AppLayout from '../layouts/AppLayout'
import DocumentLayout from "../layouts/DocumentLayout"
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "../store"

const beforeEnterCheckerSeeQuotes = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_QUOTES
    let seeTeam = store.getters.currentRights.SEE_TEAM_QUOTES
    let seeAll = store.getters.currentRights.SEE_ALL_QUOTES

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddQuote = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_QUOTE && !store.getters.currentRights.UPDATE_QUOTE) return next(redirect)
  }

  next()
}

export default [
  /** QUOTE */
  {
    path: '/:company_url/quotes',
    name: 'QuotesList',
    component: () => import('../pages/company/quote/QuotesList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeQuotes(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.quote_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/quote/dunnings',
    name: 'QuoteDunningsList',
    component: () => import('../pages/company/quote/QuoteDunnings'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeQuotes(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.invoices_menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/quote/:id/html',
    name: 'QuoteHtml',
    component: () => import('../pages/company/quote/QuoteHTML'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        return next({ name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })
      }
      await beforeEnterCheckerSeeQuotes(to, from, next, { name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    // Ici il n'y a pas de authenticate true pour permettre la redirection vers la vue client si la personne n'a pas l'accès ce document en vue HTML Company
    meta: { title: 'quote', layout: AppLayout, activeMenu: "app", company: { auth: true, redirect: (to) => ({ name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } }) } }
  },
  {
    path: '/:company_url/quote/:id/update',
    name: 'QuoteUpdate',
    component: () => import('../pages/company/quote/QuoteForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddQuote(to, from, next, { name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_quote', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/quote/:id/reminder-mail',
    name: 'QuoteReminderMail',
    component: () => import('../pages/company/SendMail'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'sendmail.title_nav', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },

  {
    path: '/:company_url/quote/create',
    name: 'QuoteCreate',
    component: () => import('../pages/company/quote/QuoteForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddQuote(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'documents.add_quote', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/quote/:id/pdf',
    name: 'QuotePdf',
    component: () => import('../pages/document/DocumentPDF.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeQuotes(to, from, next, { name: 'DocumentHtmlWithoutCompanyUrl', params: { id: to.params.id } })

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'quote', layout: DocumentLayout, company: true }
  },
  {
    path: '/:company_url/quote/:id/send-mail',
    name: 'QuoteSendMail',
    component: () => import('../pages/company/SendMail'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'sendmail.title_nav', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  /** REDIRECTIONS ANCIENNES ROUTES */
  {
    path: '/:company_url/quote/pdf/:id',
    redirect: to => {
      return { name: 'QuotePdf', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  },
  {
    path: '/:company_url/quote/html/:id',
    redirect: to => {
      return { name: 'QuoteHtml', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  },
  {
    path: '/:company_url/quote/update/:id',
    redirect: to => {
      return { name: 'QuoteUpdate', params: { company_url: to.params.company_url, id: to.params.id } }
    }
  }
]
