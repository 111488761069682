const currencyFormatter = require('currency-formatter')
const moment = require('moment-timezone')
const CryptoJS = require('crypto-js')

/**
* Get parameter by name
*
* @param   {[type]} name                       [description]
* @param   {[type]} [url=window.location.href] [description]
* @return  {[type]}                            [description]
*/
export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, {type: contentType})
  return blob
}

export const addScript = (src = '') => {
  var s = document.createElement('script')
  s.setAttribute('src', src)
  document.body.appendChild(s)
}

export const openBase64PDF = (base64pdf) => {
  const contentType = 'application/pdf'
  const b64Data = base64pdf
  const blob = b64toBlob(b64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl)
}

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const price = (number, lang = 'fr-FR', currency = 'EUR') => {
  return currencyFormatter.format(number, { code: currency, locale: lang })
}

export const quantity = (nb) => {
  return Math.round(Number(nb))
}

export const sleep = function (time) {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve()
    }, time)
  })
}

export const randomIntFromInterval = function (min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const objectDifference = function (obj1, obj2, specificsKeys = null) {
  const objReturn = {...obj1}

  const keys = specificsKeys ? Object.keys(specificsKeys) : Object.keys(obj1)

  keys.forEach(key => {
    if (typeof obj1[key] === 'object') {
      const subKeys = specificsKeys === null ? null : (specificsKeys[key] === null ? null : specificsKeys[key])
      const result = objectDifference(obj1[key], obj2[key], subKeys)
      if (Object.keys(result).length === 0) {
        delete objReturn[key]
      } else {
        objReturn[key] = result
      }
    } else {
      if (obj1[key] === obj2[key]) {
        delete objReturn[key]
      }
    }
  })

  return objReturn
}

export const objectOverwrite = function (obj1, obj2, specificsKeys = null) {
  const objReturn = {...obj1}

  const keys = specificsKeys ? Object.keys(specificsKeys) : Object.keys(obj2)
  keys.forEach(key => {
    if (typeof obj1[key] === 'object' && !obj1) {
      const subKeys = specificsKeys === null ? null : (specificsKeys[key] === null ? null : specificsKeys[key])
      objReturn[key] = objectOverwrite(obj1[key], obj2[key], subKeys)
    } else {
      if (!obj1[key] && obj2[key]) {
        objReturn[key] = obj2[key]
      }
    }
  })

  return objReturn
}

export const dataSnifer = function (data, fields) {
  if (!data) return null
  if (typeof data !== 'object') return null
  if (!fields || fields === '' || (Array.isArray(fields) && fields.length === 0)) return data

  let newData = {}

  let formatFields
  if (typeof fields === 'string') {
    formatFields = fields.split(' ')
  } else {
    formatFields = fields
  }

  if (!Array.isArray(formatFields)) return null

  formatFields.forEach(field => {
    if (data[field] !== undefined) {
      if (typeof field === 'object') {
        if (!field.key) return null

        switch (field.type) {
          case 'object':
            newData[field.key] = dataSnifer(data[field.key], field.fields)
            break
          case 'number':
            newData[field.key] = Number(field.key)
            break
          case 'date':
            newData[field.key] = new Date(field.key)
            break
          default:
            newData[field.key] = data[field.key]
        }
      } else {
        newData[field] = data[field]
      }
    }
  })

  return newData
}

export const getSort = function (sortBy = 'createdAt', sortDirection = 'asc') {
  let sort = ''

  if (sortDirection === true || sortDirection === 'desc' || sortDirection === 'DESC') {
    sort = '-'
  }

  sort += sortBy

  return sort
}

export const setSort = function (sort = 'createdAt') {
  let sortBy = sort
  let sortDirection = false

  if (sort.charAt(0) === '-') {
    sortDirection = true
    sortBy = sort.slice(1, sort.length)
  }

  return [sortBy, sortDirection]
}

export const isBirthday = function (birthdate) {
  if (!birthdate) return false
  let birthD = moment.tz(birthdate, 'Europe/Paris')

  let today = moment().tz('Europe/Paris')

  return today.date() === birthD.date() && today.month() === birthD.month()
}

export const getProjection = function (fields) {
  return btoa(fields)
}

export const redirectError = function (err) {
  if (err.response && err.response.status) {
    if (err.response.status === 401) return this.$router.push({ name: 'Login' })
    if (err.response.status === 403) return this.$router.push({ name: 'Error403' })
    if (err.response.status === 404) return this.$router.push({ name: 'Error404' })
  }

  return this.$router.push({ name: 'Error500' })
}

export const isCompanyType = function (companyTypes, currentCompany, list) {
  if (!currentCompany.company_type) return false

  let companyTypeList = JSON.parse(JSON.stringify(companyTypes)).filter(cT => list.includes(cT.name))
  return companyTypeList.findIndex(ctl => ctl._id === currentCompany.company_type) >= 0
}
